
















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component
export default class CustomersList extends Vue {
  headers = []

  customers = []
  hasDefaultWebshop = false

  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: 200,
    sortBy: [],
  }

  tableSearch = ''
  loadingData = true
  loadingAutoLogin = false

  settings = null
  informationPanel = [1]

  created() {
    this.headers = [
      {
        text: this.$t('c:master-login-customers-list-table-headers:#'),
        value: 'customerNumber',
      },
      {
        text: this.$t('c:master-login-customers-list-table-headers:Customer'),
        value: 'name',
      },
      {
        text: this.$t('c:master-login-customers-list-table-headers:City'),
        value: 'city',
      },
      {
        text: this.$t('c:master-login-customers-list-table-headers:Domain'),
        value: 'domain',
      },
      {
        text: this.$t('c:master-login-customers-list-table-headers:Last 6 months turnover'),
        value: 'turnover',
      },
      {
        text: this.$t('c:master-login-customers-list-table-headers:Login'),
        value: 'autoLoginUrl',
      },
    ]

    this.loadingData = true

    this.$axios
      .get('/v3/customers/list/get_initial_data')
      .then((response) => {
        const data = response.data.data

        this.customers = data.customers
        this.hasDefaultWebshop = data.hasDefaultWebshop
        this.settings = data.settings
      })
      .catch((err) => {
        err.response.data.errors.forEach((v, i) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
      .finally(() => {
        this.loadingData = false
      })
  }

  autoLogin(item) {
    let customerId = 0
    if (item !== null) {
      customerId = item.id
    }
    this.loadingAutoLogin = true

    this.$axios
      .get('/v3/customers/list/get_auto_login_url/' + customerId)
      .then((response) => {
        window.open(response.data.data.url, '_blank')
      })
      .catch((err) => {
        err.response.data.errors.forEach((v, i) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
      .finally(() => {
        this.loadingAutoLogin = false
      })
  }
}
